/* #### Generated By: http://font.download #### */

    @font-face {
    font-family: 'Amazon Ember Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Regular'), url('./amazon-ember/Amazon Ember.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Italic'), url('./amazon-ember/Amazon Ember Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Thin'), url('./amazon-ember/Amazon Ember Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Thin Italic'), url('./amazon-ember/Amazon Ember Thin Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Light';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Light'), url('./amazon-ember/Amazon Ember Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Light Italic'), url('./amazon-ember/Amazon Ember Light Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Medium'), url('./amazon-ember/Amazon Ember Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Medium Italic'), url('./amazon-ember/Amazon Ember Medium Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Bold'), url('./amazon-ember/Amazon Ember Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Bold Italic'), url('./amazon-ember/Amazon Ember Bold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Heavy'), url('./amazon-ember/Amazon Ember Heavy.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Heavy Italic'), url('./amazon-ember/Amazon Ember Heavy Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Cd RC Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Cd RC Regular'), url('./amazon-ember/Amazon Ember Cd RC.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Display Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Display Regular'), url('./amazon-ember/Amazon Ember Display.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Duospace Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Duospace Regular'), url('./amazon-ember/Amazon Ember Duospace.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Mono Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Mono Regular'), url('./amazon-ember/Amazon Ember Mono.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember V2 Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember V2 Regular'), url('./amazon-ember/Amazon Ember V2.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Cd RC Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Cd RC Italic'), url('./amazon-ember/Amazon Ember Cd RC Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Cd RC Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Cd RC Thin'), url('./amazon-ember/Amazon Ember Cd RC Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Cd RC Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Cd RC Thin Italic'), url('./amazon-ember/Amazon Ember Cd RC Thin Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Cd RC Light';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Cd RC Light'), url('./amazon-ember/Amazon Ember Cd RC Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Display Light';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Display Light'), url('./amazon-ember/Amazon Ember Display Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Display Light';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Display Light'), url('./amazon-ember/Amazon Ember Display Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Cd RC Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Cd RC Light Italic'), url('./amazon-ember/Amazon Ember Cd RC Light Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Display Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Display Medium'), url('./amazon-ember/Amazon Ember Display Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Display Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Display Medium Italic'), url('./amazon-ember/Amazon Ember Display Medium Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Cd RC Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Cd RC Bold'), url('./amazon-ember/Amazon Ember Cd RC Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Duospace Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Duospace Bold'), url('./amazon-ember/Amazon Ember Duospace Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Mono Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Mono Bold'), url('./amazon-ember/Amazon Ember Mono Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember V2 Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember V2 Bold'), url('./amazon-ember/Amazon Ember V2 Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Cd RC Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Cd RC Bold Italic'), url('./amazon-ember/Amazon Ember Cd RC Bold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Display Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Display Bold Italic'), url('./amazon-ember/Amazon Ember Display Bold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Display Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Display Heavy'), url('./amazon-ember/Amazon Ember Display Heavy.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Amazon Ember Display Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Amazon Ember Display Heavy Italic'), url('./amazon-ember/Amazon Ember Display Heavy Italic.woff') format('woff');
    }