@layer emt {

	.emt-exam-badge {
		border-radius: var(--border-radius);
		padding: 0.25em 0.5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: 0.3px;
	}

	.emt-exam-badge.status-complete {
		background: var(--green-200);
		color: var(--green-800);
	}

	.emt-exam-badge.status-in-progress {
		background: var(--blue-200);
		color: var(--blue-800);
	}

	.emt-exam-badge.status-assigned {
		background: var(--orange-200);
		color: var(--orange-800);
	}

	.emt-exam-badge.status-revoked {
		background: var(--red-200);
		color: var(--red-800);
	}


	.card {
		background: var(--surface-card);
		padding: 1.25rem;
		margin-bottom: 2rem;
		box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
		border-radius: var(--border-radius);
	
		&:last-child {
			margin-bottom: 0;
		}
	}

	.dt-buttons {
		display: none;
	}
	
	.emt-no-padding {
		padding: 0 !important;
	}
	
	.emt select {
		height: unset;
	}
	.emt div.dataTables_wrapper div.dataTables_length select {
		width: 100%;
	}
	.emt .dataTables_processing.card {
		padding: 4px;
		opacity: .9;
		background-color: #fff;
		font-weight: 600;
		font-size: 16px;
		color: #444;
		box-shadow: 1px 1px 1px rgba(0,0,0,.5);
		border-radius: 25px;
	}
	.emt div.dataTables_wrapper select {
		height: calc(1.5em + .5rem + 2px);
		line-height: unset;
		padding: .25rem .5rem;
	}
	.emt .nav-pills .nav-link.active,
	.emt .nav-pills .show>.nav-link {
		background-color: #0073aa;
		color: #fff;
	}
	.emt .border-primary {
		border-color: #0073aa!important;
	}
	.emt .btn:focus,
	.emt a:focus,
	.emt .btn-light:not(:disabled):not(.disabled).active:focus,
	.emt .btn-light:not(:disabled):not(.disabled):active:focus,
	.emt .show>.btn-light.dropdown-toggle:focus {
		box-shadow: none;
	}
	.emt .bootstrap-select .dropdown-toggle:focus {
		outline: none !important;
	}
	.emt ul.nav .nav-link {
		color: #0073aa;
	}
	.emt .dropdown-item.active, .dropdown-item:active {
		background-color: #0073aa;
	}
	.emt .emt-input-small {
		height: auto;
		font-size: 1rem;
	}
	.emt .emt-btn-xs {
		font-weight: 600;
		padding: .20rem .4rem;
		font-size: .8rem;
		line-height: 1.45;
	}
	.emt .emt-bg-primary,
	.emt .emt-btn-primary {
		background-color: #0073aa;
	}
	.emt .emt-btn-outline-primary {
		background-color: #fff !important;
		border: 1px solid #0073aa !important;
		color: #0073aa !important;
		transition: .15s all ease-out !important;
	}
	.emt .emt-btn-outline-primary:hover {
		background-color: #0073aa !important;;
		color: #fff !important;
	}
	.emt .emt-btn-success {
		background-color: #389040;
		color: #fff;
	}
	.emt .emt-btn-danger {
		background-color: #dc3232;
		color: #fff;
	}
	.emt .emt-btn-warning {
		background-color: #f56e28;
		color: #fff;
	}
	.emt .emt-text-small {
		font-size: .9rem;
	}
	.emt .emt-text-primary {
		color: #0073aa;
	}
	.emt .emt-text-success {
		color: #36a940;
	}
	.emt .emt-text-danger {
		color: #dc3232;
	}
	.emt .emt-btn-primary {
		color: #fff;
	}
	.emt .emt-important {
		color: red;
	}
	.emt-font-extra-large {
		font-size: 1.4em !important;
	}
	.emt-font-large {
		font-size: 1.2em !important;
	}
	.emt-font-medium {
		font-size: 1em !important;
	}
	.emt-font-small-medium {
		font-size: .85em !important;
	}
	.emt-font-small {
		font-size: .8em !important;
	}
	.emt-font-bold {
		font-weight: 600;
	}
	.emt-font-light {
		font-weight: 400;
	}
	.emt-cursor-pointer {
		cursor: pointer;
	}
	.emt .wp-editor-wrap {
		position: relative;
		top: -2rem;
	}
	.emt .emt-option-editor-btn {
		max-width: 95px;
	}
	.emt .emt-correct-option {
		position: relative;
		top: -1px;
	}
	@media(max-width: 782px) {
		.emt .emt-correct-option {
			position: relative;
			top: -6px;
		}
	}
	.emt textarea {
		min-height: 62px;
	}
	.emt textarea:focus {
		box-shadow: none;
	}
	.emt .emt-hide {
		display: none;
	}
	.emt-table-block-small {
		font-size: .9rem;
	}
	.emt .emt-section {
		border: 1px solid #ddd;
		margin-bottom: 1rem;
	}
	.emt .emt-section-header {
		padding: .5rem .7rem;
		font-size: 1.15rem;
		font-weight: 400;
		border-bottom: 1px solid #ddd;
		background-color: #f1f1f1;
	}
	.emt .emt-section-header::after {
		content: '';
		display: table;
		clear: both;
	}
	.emt .emt-section-body {
		padding: .7rem;
	}
	.emt .emt-section-body .list-group li {
		padding: 7px 5px;
	}
	.emt .emt-main-header-title {
		margin-top: 1rem;
		margin-bottom: 0;
		padding: 1rem 0;
		text-align: center;
		font-size: 2rem;
		font-weight: 400;
		color: #333;
		background-color: #f1f1f1;
	}
	.emt .emt-section-heading-block {
		background-color: #0073aa;
		color: #fff;
		padding: .3rem .5rem;
		margin-top: 1.4rem;
		margin-bottom: 1.4rem;
		border-radius: 10px;
		box-shadow: 0 4px 10px rgb(169, 227, 255);
	}
	.emt .emt-section-heading {
		font-size: 1.4rem;
		font-weight: 600;
	}
	.emt .emt-form-sub-heading {
		font-size: 1.24rem;
		padding-top: .2rem;
		padding-bottom: .8rem;
	}
	.emt .emt-form-section {
		padding: 1rem;
		border: 1px solid rgba(0, 0, 0, .125);
		border-collapse: collapse;
	}
	.emt .form-row > div {
		padding: 0 .8rem;
	}
	.emt .emt-photo {
		width: 125px;
		height: 160px;
		border: 1px solid #e0e0e0;
	}
	.emt .emt-question-option-box {
		box-shadow: 1px 2px 2px rgba(0, 0, 0, .4);
	}
	.emt .emt-question-box p {
		font-size: 1rem;
	}
	.emt .emt-question-box {
		margin-top: 20px;
		padding: .7em 2em 1em;
		border: 1px solid #ccd0d4;
		background: #fff;
		box-shadow: 1px 1px 4px rgba(0, 0, 0, .2);
	}
	/* Dashboard start. */
	.emt .emt-dashboard-section {
		padding: .75rem 1rem;
		color: #333;
		box-shadow: 1px 2px 5px rgba(0, 0, 0, .25);
		border-radius: .5rem 0 .5rem 0;
		margin-bottom: 1rem;
	}
	.emt .emt-dashboard-section-heading {
		font-size: 1.25rem;
		font-weight: 600;
		padding: .25rem 0;
		border-bottom: 1px solid #e5e5e5;
	}
	.emt .emt-stats .col-md-6 {
		padding-right: 0;
	}
	.emt .emt-stats-block {
		position: relative;
		padding: 1rem;
		margin: 1rem 1rem 1rem 0;
		background: rgb(10,106,152);
		background: linear-gradient(90deg, rgba(10,106,152,1) 0%, rgba(0,135,199,1) 35%, rgba(10,150,218,1) 100%);
		color: #fff;
		box-shadow: 1px 2px 5px rgba(0, 0, 0, .2);
	}
	.emt .emt-stats-block--red {
		background: rgb(191,29,29);
		background: linear-gradient(90deg, rgba(191,29,29,1) 0%, rgba(220,50,50,1) 35%, rgba(237,84,84,1) 100%);
	}
	.emt .emt-stats-block--green {
		background: rgb(46,120,53);
		background: linear-gradient(90deg, rgba(46,120,53,1) 0%, rgba(57,148,66,1) 35%, rgba(68,180,78,1) 100%);
	}
	.emt .emt-stats-block--orange {
		background: rgb(205,91,30);
		background: linear-gradient(90deg, rgba(205,91,30,1) 0%, rgba(226,105,43,1) 35%, rgba(235,128,73,1) 100%);
	}
	.emt .emt-stats-block .emt-stats-title {
		font-size: .95rem;
		font-weight: 400;
	}
	.emt .emt-stats-block .emt-stats-title i {
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 1.05rem;
	}
	.emt .emt-stats-block .emt-stats-value {
		display: block;
		font-weight: 600;
		font-size: 2rem;
	}
	.emt .emt-dashboard-exam-links {
		display: block;
	}
	.emt .emt-dashboard-exam-links a {
		font-weight: 600;
		padding: .20rem .4rem;
		margin-bottom: .3rem;
		font-size: .8rem;
		line-height: 1.45;
	}
	.emt .emt-recent-exams-list li {
		padding: 1rem;
		box-shadow: 1px 2px 5px rgba(0, 0, 0, .2);
		clear: both;
	}
	.emt .emt-recent-exams-list .emt-dashboard-exam-link {
		text-decoration: none;
		color: #0073aa;
		font-size: 1.04rem;
		font-weight: 600;
		margin: .5rem 0;
		padding-bottom: .2rem;
		border-bottom: 1px solid #e5e5e5;
		display: inline-block;
	}
	.emt .emt-recent-exams-list .emt-dashboard-exam-link:focus {
		box-shadow: none;
	}
	.emt .emt-recent-exams-list .emt-dashboard-exam-date-time {
		color: #474f58;
		font-weight: 500;
		font-size: .92rem;
		float: right;
		clear: both;
	}
	.emt .emt-recent-exams-list .emt-dashboard-duration {
		color: #1a1a1a;
		font-weight: 400;
		float: right;
	}
	.emt .emt-dashboard-exams-not-found {
		padding: 1rem 0;
		font-size: 1.05rem;
		color: #333;
	}
	.emt .emt-dashboard-exams-not-found i {
		margin-right: 2px;
		color: #dc3232;
	}
	.emt .emt-quick-links-block {
		padding: 1rem;
		box-shadow: 1px 2px 5px rgba(0, 0, 0, .2);
	}
	.emt .emt-quick-links-block a {
		display: inline-block;
		margin: .5rem 1rem .5rem 0;
	}
	/* Dashboard end. */
	/* Exam timer start. */
	.emt .emt-exam-timer {
		box-sizing: border-box;
		display: block;
		width: 350px;
		padding-top: 8px;
		padding-bottom: 16px;
		padding-left: 16px;
		padding-right: 16px;
		margin-top: .2rem;
		border: 2px solid #dcdcdc;
		background-color: #eee;
		color: #333;
	}
	.emt .emt-exam-timer-clock {
		position: relative;
	}
	.emt .emt-exam-timer-value {
		display: inline-block;
		font-size: 23px;
		font-weight: 600;
		margin: 0 25px;
	}
	.emt .emt-exam-timer-unit {
		position: absolute;
		top: 22px;
		left: 18px;
		font-size: 11px;
		font-weight: 400;
	}
	/* Exam timer end. */
	.emt .emt-student-new-user,
	.emt .emt-student-existing-user,
	.emt .emt-import-from-csv {
		display: none;
	}
	.emt .page-link {
		color: #0073aa;
		background-color: #fff;
		border: 1px solid #dee2e6;
	}
	.emt .page-item.active .page-link {
		color: #fff;
		background-color: #0073aa;
		border-color: #0073aa;
	}
	.emt .emt-remove-item {
		cursor: pointer;
		padding: 4px 5px;
		font-size: 0.7rem;
		border-radius: 50%;
	}
	.emt .emt-pagination {
		display: flex;
		justify-content: flex-end;
		margin: 1rem 0;
	}
	.emt .emt-pagination .page-numbers {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: .4rem;
		width: 1.8rem;
		height: 1.8rem;
		line-height: 1.8rem;
		font-size: .9rem;
		border: 1px solid #0073aa;
		text-decoration: none;
		background-color: #0073aa;
		color: #fff;
		transition: .15s all ease-out;
	}
	.emt .emt-pagination .page-numbers:focus,
	.emt .emt-pagination .page-numbers:active {
		outline: none;
		box-shadow: none;
	}
	.emt .emt-pagination .page-numbers:hover,
	.emt .emt-pagination .page-numbers.current,
	.emt .emt-pagination .page-numbers.dots {
		background-color: #fff;
		color: #0073aa;
		border: 1px solid #ccc;
	}

}

.emt-label {
	font-size: 1rem;
	font-weight: 600;
	color: #333;
	width:100%;
}

.emt-field {
	font-size: 1rem;
	font-weight: 400;
	color: #333;
	width:100%;
}
