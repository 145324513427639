.ui-contextmenu ul li a {
    text-decoration: none;
    color: #000;
}

.ui-contextmenu .ui-menuitem-text {
    /* font-family: Poppins !important; */
    /* font-family: Metropolis !important; */
    font-family: "Ubuntu Sans", "Amazon Ember Regular" !important;
    font-size: 13px !important;
}

.ui-contextmenu .ui-menuitem :hover {
    background-color: #f4f5f8;
}
