.p-tree {
    width: 100%;
    border: none;
}

body .p-tree.ui-widget-content {
    border: none !important;
}

span.p-treenode-label {
    /* font-family: Poppins !important; */
    /* font-family: Metropolis !important; */
    font-family: "Ubuntu Sans", "Amazon Ember Regular" !important;
    line-height: 24px !important;
    font-size: 14px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

span.p-treenode-icon {
    line-height: 24px !important;
    font-size: 1.2rem !important;
}

.p-tree .ui-chkbox .ui-chkbox-icon {
    margin-left: 0px;
}

.p-tree .p-treenode-children {
    padding-left: 20px !important;
}

.hidden-tree-node {
    display: none;
}

p-tree[selectionmode="checkbox"] .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background-color: transparent !important;
    color: #333 !important;
    font-weight: 300 !important;
}

span.p-treenode-label {
    line-height: 18px !important;
    font-size: 13px !important;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content .ui-chkbox .ui-chkbox-icon {
    margin: -1px 0 0 -1px;
}

body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
    font-size: 16px;
}

body .ui-chkbox .ui-chkbox-box {
    width: 16px;
    height: 16px;
}

body .ui-chkbox {
    width: 16px;
    height: 16px;
}

body .p-tree .p-tree-container .p-treenode {
    padding: 0;
}
